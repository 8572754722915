<template lang="pug">
include ../pug/svg
section#dashbaord
	p Welcome back {{usr.firstName}}!
	//-div#modal(ref="modalTest" draggable="true") Draggable?
	//-modal(name="example") This is an example
	
	//-h1 Dashboard
	//-div#example-1
		button.but(@click="show = !show") Toggle render
		transition(name="fadey")
			p(v-if="show") hello
</template>

<script>
export default {
	name: 'Dashboard',
	data() {
		return {
			show: true,
		}
	},
	computed: {
		usr() {
			return this.$store.state.auth.user;
		},		
	},
	mounted() {		
		this.$store.dispatch('gui/setHeader', {
			title: 'Dashboard',
			backRoute: {
				text: 'Signout',
				name: 'Signin',
				params: {
					signout: true
				},
			},
		});
	}
}
</script>

<style lang="scss">

.fadey-enter-active, .fadey-leave-active {
	transition: opacity 1s;
}
.fadey-enter, .fadey-leave-to {
	opacity: 0;
}


</style>
